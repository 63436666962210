
import { defineComponent } from 'vue';
import { fetchTeamStats, TeamStats } from '@/services/stats';
import { store } from '@/store/store';
import { CURRENT_SEASON, STATE } from '@/constants';
import { ACTION } from '@/store/actions';

export default defineComponent({
    name: 'TheTeamStats',
    props: {
        teamName: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            games: 0,
            win: 0,
            lost: 0,
            nul: 0,
            points: 0,
            pf: 0,
            pa: 0,
            diff: 0,
            awaitingApproval: false
        } as TeamStats;
    },
    computed: {
        diffStr(): string {
            if (this.diff > 0) return `+${this.diff.toString()}`;
            else return this.diff.toString();
        },
        diffType(): string {
            if (this.diff > 0) return 'success';
            else return 'warn';
        },
        teamRank(): number {
           return store.getters.teamRank(this.teamName);
        }
    },
    methods: {
        async syncStats() {
            const season = store.state.currentSeasonView === CURRENT_SEASON? undefined: store.state.currentSeasonView
            const stats = await fetchTeamStats(this.teamName, season);
            this.points = stats.points;
            this.games = stats.games;
            this.win = stats.win;
            this.lost = stats.lost;
            this.nul = stats.nul;
            this.diff = stats.diff;
            this.pa = stats.pa;
            this.pf = stats.pf;
            this.awaitingApproval = stats.awaitingApproval
        }
    },
    watch: {
        teamName() {
            this.syncStats();
        }
    },
    async mounted() {
        if(store.state.seasonRankingRequestStatus.status === STATE.STALE) {
            store.dispatch(ACTION.FETCH_SEASON_RANKINGS, undefined);
        }
        this.syncStats();
    }
});
