
import { defineComponent } from 'vue';
import TheTeamPlayers from '@/components/TheTeamPlayers.vue';
import TheTeamStats from '@/components/TheTeamStats.vue';
import TheScheduleTable from '@/components/TheScheduleTable.vue';
import TeamLabel from '@/components/TeamLabel.vue';
import { TeamInterface } from '@/services/teams';
import { store } from '@/store/store';
import { MUTATION } from '@/store/mutations';
import { ACTION } from '@/store/actions';
import { divisionLongDisplayName } from '@/utils';
import { STATE } from '@/constants';

export enum TAB {SCHEDULE, PLAYERS, RANKINGS, SOCIAL}

export default defineComponent({
    name: 'Team',
    components: { TheTeamPlayers, TheScheduleTable, TheTeamStats, TeamLabel },
    data() {
        return {
            tabSelected: TAB.SCHEDULE,
            TAB_TYPES: TAB,
            teamImageVisible: true,
            filterSetName: '__team__'
        }
    },
    computed: {
        teamName(): string{
            return decodeURI(this.$route.params.teamName as string);
        },
        teamExists(): boolean {
            return this.team !== undefined;
        },
        team(): TeamInterface {
            return store.getters.teamInfo(this.teamName);
        },
        divisionDisplay(): string {
            return divisionLongDisplayName(this.team.division, this.team.pool);
        },
        hasPicture(): boolean {
            return (
                this.team.picture !== undefined
                && this.team.picture != ''
            );
        },
        teamIFavorite(): boolean {
            return store.getters.teamsIFavorite.some(t => {
                return t._id === this.team._id;
            });
        },
        teamICaptain(): boolean {
            return store.getters.teamsICaptain.some(t => {
                return t._id === this.team._id;
            });
        },
        hasLogo(): boolean {
            return this.team.logo !== undefined;
        },
        isAdmin(): boolean {
            return store.getters.isAdmin;
        },
        canUpdateTeamPicturesCaptain(): boolean {
            return store.getters.isTeamCaptain(this.team.name) || store.getters.isAdmin;
        }
    },
    methods: {
        togglePlayers() {
            this.tabSelected = this.TAB_TYPES.PLAYERS;
        },
        toggleSchedule() {
            this.tabSelected = this.TAB_TYPES.SCHEDULE;
        },
        toggleSocial() {
            this.tabSelected = this.TAB_TYPES.SOCIAL;
        },
        toggleRankings() {
             this.tabSelected = this.TAB_TYPES.RANKINGS;
        },
        toggleTeamImage() {
            this.teamImageVisible = !this.teamImageVisible;
        },
        editTeam() {
            store.commit(MUTATION.SHOW_ADD_TEAM_DIALOG, {
                show: true, team: this.team
            });
        }
    },
    watch: {
        teamName() {
            store.commit(MUTATION.RESET_SCHEDULE_FILTERS, this.filterSetName);
            store.commit(MUTATION.SET_TEAM_NAMES_FILTERS, {filterSet: this.filterSetName, teamNames: [this.teamName]});
            store.commit(MUTATION.SET_SELECTED_MATCH_IDX, -1);
        }
    },
    mounted() {
        if(store.state.fieldsRequestStatus.status === STATE.STALE) {
            store.dispatch(ACTION.FETCH_FIELDS);
        }
        store.commit(MUTATION.RESET_SCHEDULE_FILTERS, this.filterSetName);
        store.commit(MUTATION.SET_SELECTED_MATCH_IDX, -1);
        store.commit(MUTATION.SET_TEAM_NAMES_FILTERS, {filterSet: this.filterSetName, teamNames: [this.teamName]});
    }
});
