
import { defineComponent } from 'vue';
import ConfirmAction from '@/components/ConfirmAction.vue';
import { TeamInterface, PlayerInterface } from '@/services/teams';
import { store } from '@/store/store';
import { ACTION } from '@/store/actions';
import { GENDER_TYPE, STATE } from '@/constants';

export default defineComponent({
    name: 'Team',
    components: { ConfirmAction },
    props: {
        teamName: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            newPlayerName: '',
            newPlayerGender: '',
            newPlayerNumber: 0,
            newlyAddedPlayerName: '',
        };
    },
    computed: {
        team(): TeamInterface {
            return store.getters.teamInfo(this.teamName);
        },
        players(): PlayerInterface[] {
            const players = store.getters.playersPerTeam[this.teamName] ?? [];
            return players.filter(p => p.team === this.teamName).sort((p1, p2) => {
                if (p1.number > p2.number) return 1;
                else if(p1.number < p2.number) return -1;
                else return p1.name.localeCompare(p2.name);
            });
        },
        genderTypes(): string[] {
            return Object.values(GENDER_TYPE);
        },
        canUpdateTeam(): boolean {
            return store.getters.canUpdateTeam(this.teamName);
        },
        playerAddValid(): boolean {
            return this.newPlayerNumber >= 0 && this.newPlayerName !== '' && this.newPlayerGender !== '';
        },
        hasPlayers(): boolean {
            return this.players.length > 0;
        },
        manSelected(): boolean {
            return this.newPlayerGender == GENDER_TYPE.MAN;
        },
        womanSelected(): boolean {
            return this.newPlayerGender == GENDER_TYPE.WOMAN;
        }
    },

    methods: {
        addPlayer() {

            // Read data
            const player = {
                number: this.newPlayerNumber,
                gender: this.newPlayerGender,
                name  : this.newPlayerName,
                team  : this.teamName
            };

            // Add player
            store.dispatch(ACTION.ADD_PLAYER, player);

            // Locally store newlyAddedPlayer for display
            this.newlyAddedPlayerName = this.newPlayerName;

            // Reset values
            this.newPlayerNumber = 0;
            this.newPlayerGender = '';
            this.newPlayerName = '';

        },
        removePlayer(player: PlayerInterface) {
            store.dispatch(ACTION.REMOVE_PLAYER, player);
        },
        lastPlayerAdded(playerName: string) {
            return playerName === this.newlyAddedPlayerName;
        },
        isMan(gender: string): boolean {
            return gender == GENDER_TYPE.MAN;
        },
        isWoman(gender: string): boolean {
            return gender == GENDER_TYPE.WOMAN;
        },
    },
    async mounted() {
        if(store.state.playersRequestStatus.status === STATE.STALE) {
            store.dispatch(ACTION.FETCH_PLAYERS, undefined);
        }
    }
});
